import $ from 'jquery';

$(function() {
    // alert element
    if ($('#alertModal').length > 0) {
        $('#alertModal').modal('show');
    }

    $(".open-subnav-icon").on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('active');

        if($(this).parent().next('.submenu').length){
            $(this).parent().next('.submenu').toggle().toggleClass('active');
        } else {
            $(this).next('.submenu').toggle().toggleClass('active');
        }
    });

    $(".meta-navigation .nav-link").on('click', function(e) {
        if($(this).parent().next('.submenu').length){
            e.preventDefault();

            $(this).toggleClass('active');
            $(this).parent().next('.submenu').toggle().toggleClass('active');
        }
    });

    $(".navbar-toggler").on('click', function() {
       $(".nav-open-icon, .nav-close-icon").toggleClass('active');
    });

    $(".publikation-search").submit(function(e){
        e.preventDefault();
        let searchValue = $(".publikation-search-input").val();

        $.ajax({
            type: "GET",
            url: "/api/ajax-publikationen?" + "psq=" + searchValue + "&cache=" + Math.floor(Date.now() / 1000),
            success: function(res) {
                if( res.length > 0 ) {
                    $(".publikation-list").html(res);
                }
            },
            error: function(err) {
                console.log("error:" + err.responseText);
            }
        });
    });

    //reset news
    $("#resetSearchFilter").on('click', function(e) {
        e.preventDefault();
        let siteId = $("[data-site-id]").data("siteId");
        $("#newsSearchinput").val('');
        $(".news-category-filter").removeClass('active');

        //remove getparam when reset filters
        window.history.pushState('', '', location.pathname);
        
        //get content
        $.ajax({
            type: "GET",
            url: "/api/ajax-news?" + "site=" + siteId,
            success: function(res) {
                if( res.length > 0 ) {
                    $(".news-list").html(res);
                }
            },
            error: function(err) {
                console.log("error:" + err.responseText);
            }
        });
    })

    //search news for content via input field
    $(".news-search").submit(function(e) {
        e.preventDefault();
        let siteId = $("[data-site-id]").data("siteId");
        let searchValue = $(".news-search-input").val();
        
        //reset category filter active state
        $(".news-category-filter").removeClass('active');

        // If caching makes issues add ' + "&cache=" + Math.floor(Date.now() / 1000)' to the query
        $.ajax({
            type: "GET",
            url: "/api/ajax-news?" + "nsq=" + searchValue + "&site=" + siteId,
            success: function(res) {
                if( res.length > 0 ) {
                    $(".news-list").html(res);
                }
            },
            error: function(err) {
                console.log("error:" + err.responseText);
            }
        });
    });

    // filter news by category filter
    $(".news-category-filter").on('click', function(e){
        e.preventDefault();
        let siteId = $("[data-site-id]").data("siteId");

        let category = $(this).data('filterkategorie');
        let filterButton = $(this);
        
        //clear filters
        $(".news-category-filter").removeClass('active');
        $("#newsSearchinput").val('');

        //toggle remove class/reset filter
        if(filterButton.hasClass('active')) {
            category = '';
        }

        // If caching makes issues add ' + "&cache=" + Math.floor(Date.now() / 1000)' to the query
        $.ajax({
            type: "GET",
            url: "/api/ajax-news?" + "filtercategory=" + category + "&site=" + siteId,
            success: function(res) {
                if( res.length > 0 ) {
                    $(".news-list").html(res);
                    filterButton.addClass('active');
                }
            },
            error: function(err) {
                console.log("error:" + err.responseText);
            }
        });
    });

    $(".reset-filters").on('click', function(e) {
        e.preventDefault();
        $("[name='categoryfilter']")[0].selectedIndex = 0;
        $("[name='yearfilter']")[0].selectedIndex = 0;
        $(".publikation-filter").trigger("change");
        $(this).hide();
    });

    $(".publikation-filter").on('change', function(e) {
        let filterYear = $("[name='yearfilter']").val();
        let filterCategory = $("[name='categoryfilter']").val();
        let resetFilterButton = $(".reset-filters");

        if (!$("[name='yearfilter']")[0].selectedIndex == 0 || !$("[name='categoryfilter']")[0].selectedIndex == 0) {
            resetFilterButton.show();
        } else {
            resetFilterButton.hide();
        }

        $.ajax({
            type: "GET",
            url: "/api/ajax-publikationen?" + "filtercategory=" + filterCategory + "&filteryear=" + filterYear + "&cache=" + Math.floor(Date.now() / 1000),
            success: function(res) {
                if( res.length > 0 ) {
                    $(".publikation-list").html(res);
                }
            },
            error: function(err) {
                console.log("error:" + err.responseText);
            }
        });
    });

    // Angebote ajax call / filtering
    $(".angebot-filter-buttons .filter-button").on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('active');

        let currentBtn = $(this);

        let siteId = $("[data-site-id]").data("siteId");

        let activeKategorie = $("[data-filterkategorie].active");
        let activeArt = $("[data-filterart].active");
        let activeAlter = $("[data-filteralter].active");
        let activeInklusion = $("[data-filterinklusion].active");

        //filter arrays
        let activeKategorien = [];
        let activeArten = [];
        let activeAlters = [];
        let activeInklusions = [];

        activeKategorie.each(function(e){ activeKategorien.push( $(this).data('filterkategorie') ) });
        let kategorien = activeKategorien.join(',');

        activeArt.each(function(e){ activeArten.push( $(this).data('filterart') ) });
        let arten = activeArten.join(',');

        activeAlter.each(function(e){ activeAlters.push( $(this).data('filteralter') ) });
        let alters = activeAlters.join(',');

        activeInklusion.each(function(e){ activeInklusions.push( $(this).data('filterinklusion') ) });
        let inklusions = activeInklusions.join(',');

        $.ajax({
            type: "GET",
            url: "/api/ajax-angebote?" + "kategorie=" + kategorien + "&art=" + arten + "&altersgruppe=" + alters + "&inklusionsgruppe=" + inklusions + "&cache=" + Math.floor(Date.now() / 1000) + "&site=" + siteId,
            success: function(res) {
                if( res.length > 0 ) {
                    $(".angebote-results").html(res);
                }
                $(".filter-results-length .length-count").html($(".result-container").data('resultlength'));
            },
            error: function(err) {
                console.log("error:" + err.responseText);
            }
        });
    });

    $(".filter-results-length .length-count").html($(".result-container").data('resultlength'));

    $(".disability-helper .current-language").click(function() {
        $(".language-dropdown").toggleClass('open');
    });

    // home slider
    $('#homeSlider').carousel({
        interval: 5000,
        pause: false
    });

    // make slider controls work outside of carousel container
    $('.carousel').bind('slide.bs.carousel', function (e) {
        let index = $(e.relatedTarget).index();

        $('[data-target="#' + $(this).prop('id') + '"]').each(function (i) {
            if (i === index) {
                $(this).addClass('active');
            } else {
                $(this).removeClass('active');
            }
        });
    });

    // barrierefreiheit functions
    $(".contrast-mode").on('click', function(e) {
        if ($("body").hasClass('contrast')) {
            $(this).removeClass('active');
            $("body").removeClass('contrast');
            Cookies.remove('contrast');
        } else {
            $(this).addClass('active');
            $("body").addClass('contrast');
            Cookies.set('contrast', true);
        }
    });

    $(".fontsize-scale").on('click', function(e) {
        if ($("body").hasClass('fontscale')) {
            $(this).removeClass('active');
            $("body").removeClass('fontscale');
            Cookies.remove('fontscale');
        } else {
            $(this).addClass('active');
            $("body").addClass('fontscale');
            Cookies.set('fontscale', true);
        }
    });

    $(".angebot-filter-title").on('click', function(e) {
        let mql = window.matchMedia('(max-width: 950px)');
        if(mql.matches) {
            $(this).toggleClass('active');
            $(this).find('i').stop().toggleClass('fa-angle-down fa-angle-up');
            $(this).next().stop().slideToggle();
        }
    });

    if(Cookies.get('contrast')) {
        $("body").addClass('contrast');
        $(".contrast-mode").addClass('active');
    }
    if(Cookies.get('fontscale')) {
        $("body").addClass('fontscale');
        $(".fontsize-scale").addClass('active');
    }

    $(".alertmodal .btn").on('click', function() {
        Cookies.set('alertmodal', 'true', 1);
        $(".alertmodal").fadeOut();
        $("body").removeClass('modal-open');
    });

    if(Cookies.get('alertmodal') === undefined) {
        $(".alertmodal").removeClass('d-none');
    }

    $(".cookie-consent .btn").on('click', function() {
        Cookies.set('cookieconsent', 'true', 30);
        $(".cookie-consent").fadeOut();
    });

    if(Cookies.get('cookieconsent') === undefined) {
        $(".cookie-consent").removeClass('d-none');
    }

    // animate dropdown items
    $('.dropdown').on('show.bs.dropdown', function(e){
        $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
    });

    // animate dropdown items
    $('.dropdown').on('hide.bs.dropdown', function(e){
        $(this).find('.dropdown-menu').first().stop(true, true).slideUp(300);
    });

    // open dropdown menus on tab focus
    $('.dropdown-toggle').keyup(function(e) {
        if (e.keyCode == 9) {
            $(this).dropdown('toggle');
        }
    });

    $(".anfrage-typ .type").on('click', function(e){
        e.preventDefault();
        let formHandle = $(this).data('form');
        $(".anfrage-typ .type").removeClass('active');
        $(".form-container.component-formular").removeClass('active').hide();
        $(".form-container.component-formular[data-form="+ formHandle +"]").toggle();
        $(this).toggleClass('active');
    });

    $(".main-nav-link-opener").on('click', function(e) {
        e.preventDefault();
        let navToggle = $(this).data('navtoggle');
        let activeSection = $("body").data('entrysection');

        // remove and set activeclass for navigation
        $(".main-nav-link-opener").removeClass('active');
        $(this).toggleClass('active');

        // reset previous opened navigations
        $('.header-dropdown-navigation:not([data-navdropdown="'+ navToggle +'"])').hide();

        // slide down selected navigation
        if($(".header-dropdown-navigation").is(":visible")) {
            if(activeSection) {
                $('body').removeClass('erleben besuchen archaeologie roemerfest').addClass(activeSection);
            } else {
                $('body').removeClass('erleben besuchen archaeologie roemerfest');
            }
            $('.main-nav-link-opener').removeClass('active');
        } else {
            $('body').removeClass('erleben besuchen archaeologie roemerfest').addClass(navToggle);
        }

        $("[data-navdropdown='"+ navToggle +"']").stop().slideToggle();
    });

    // navigation close
    $(".close-dropdown-navigation").on('click', function(e) {
        e.preventDefault();
        var navToggle = $(this).data('navtoggle');
        var activeSection = $("body").data('entrysection');

        if(activeSection) {
            $('body').removeClass('erleben besuchen archaeologie roemerfest').addClass(activeSection);
        } else {
            $('body').removeClass(navToggle);
        }

        $(".header-dropdown-navigation").slideUp('normal', function() {
            $(".main-nav-link, .main-nav-link-opener").removeClass('active');
        });
    });

    $(".angebote-results, .related-angebote").on("click", '.save-to-favorites', function(e) {
        e.preventDefault();

        let currentId = $(this).attr('id');
        let favorites = [];
        const cookievalue = Cookies.get('angebotefavorites');

        if (!!cookievalue) {
            favorites = JSON.parse(cookievalue);
        }

        if (cookievalue){
            if (JSON.parse(cookievalue).includes(currentId)) {
                Cookies.set('angebotefavorites', JSON.stringify(favorites.filter((value)=>{return value !== currentId})));
            } else {
                Cookies.set('angebotefavorites', JSON.stringify(favorites.concat([currentId])));
            }
        } else {
            Cookies.set('angebotefavorites', JSON.stringify([currentId]));
        }

        $(this).toggleClass('far fas');
    });

    // on load initialize favorites / unset cache
    $(".save-to-favorites").each(function(e){
        let currentId = $(this).attr('id');
        let favorites = [];
        const cookievalue = Cookies.get('angebotefavorites');

        if (!!cookievalue) {
            favorites = JSON.parse(cookievalue);
        }

        if (cookievalue) {
            if (JSON.parse(cookievalue).includes(currentId)) {
                $(this).addClass('fas').removeClass('far');
            } else {
                $(this).addClass('far').removeClass('fas');
            }
        }
    });

    if($("#angeboteFavoritesPage").length) {
        const cookievalue = Cookies.get('angebotefavorites');

        $.ajax({
            type: "GET",
            url: "/api/ajax-angebotefavoriten?" + "ids=" + cookievalue + "&cache=" + Math.floor(Date.now() / 1000),
            success: function(res) {
                if( res.length > 0 ) {
                    $(".angebote-results").html(res);
                }
            },
            error: function(err) {
                console.log("error:" + err.responseText);
            }
        })

    }

    $(".fa-search").click(function() {
        $("#searchInput").focus().toggleClass('show');
        $("#searchComponent").toggleClass('open');
    });

    const searchInput = document.querySelector("#searchInput");
    searchInput.addEventListener("keyup", function(event) {
        if (event.key === "Enter") {
            window.location.href = searchInput.dataset.searchUrl + "?q=" + searchInput.value;
        }
    });

    $(".view-toggles i").click(function() {
        $(".view-toggles i").toggleClass('active');
       $(".events-list").toggleClass('list-view');
    });

    $(".angebot-form-opener").click(function() {
        let formHandle = $(this).data('form');
        $('.reservation-form-wrapper').hide();
        $(".angebot-form-opener").removeClass('active');

        $(this).addClass('active');
        $('.reservation-form-wrapper[data-form='+ formHandle +']').stop().slideDown();
    });

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const qrCodeSection = document.querySelector('body').dataset.sectionQrCode;
    if (qrCodeSection !== undefined) {
        const entries = JSON.parse(qrCodeSection);

        if (localStorage.getItem('preferredLanguage') === null) {
            let browserUserLang = window.navigator.language || window.navigator.userLanguage;
            let browserUserLangShort = browserUserLang.substring(0, 2);

            if (entries.hasOwnProperty(browserUserLangShort)) {
                localStorage.setItem('preferredLanguage', browserUserLangShort);
            } else {
                localStorage.setItem('preferredLanguage', 'de');
            }
        } else {
            if (!entries.hasOwnProperty(localStorage.getItem('preferredLanguage'))) {
                localStorage.setItem('preferredLanguage', 'de');
            }
        }

        if (localStorage.getItem('preferredLanguage') !== document.querySelector('html').lang.substring(0, 2)) {
            window.location.href = entries[localStorage.getItem('preferredLanguage')]
        }
    }
    
    document.querySelectorAll("#languageDropdown .dropdown-item, .language-dropdown .language-link").forEach(item => item.addEventListener('click', function(e) {
        localStorage.setItem('preferredLanguage', item.getAttribute('lang'));
    }));
});
