// Importing scripts
import * as bootstrap from 'bootstrap';
import $ from 'jquery';

import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min.js';

import '@fancyapps/fancybox';

// Custom js
import './js/cookie-consent';
import './js/scripts.js';

// Styles (Bootstrap + Custom)
import './scss/app.scss'

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}

window.bootstrap = bootstrap;
window.$ = $;
